import axios from "axios";

export const apiCall = async (payloads) => {
  const { method, header, endPoint, payload } = payloads;
  if (method === "GET") {
    if (header) {
      try {
        const { data } = await axios.get(endPoint, header);
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    } else {
      try {
        const { data } = await axios.get(endPoint);
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  } else if (method === "POST") {
    const postPayload = payload ? payload : {};
    if (header) {
      return await axios.post(endPoint, postPayload, header);
    } else {
      return await axios.post(endPoint, postPayload);
    }
  } else if (method === "PATCH") {
    const postPayload = payload ? payload : {};
    try {
      const { data } = await axios.patch(endPoint, postPayload, header);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  } else if (method === "DELETE") {
    try {
      const { data } = await axios.delete(endPoint, header);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
};
